import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box, Button } from "@mui/material";
import AceEditor from "react-ace";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-github";

const EditorSection = ({
  userCode, // 父组件传递的 main.py 的代码
  setUserCode, // 更新父组件的 userCode
  editorSettings, // 编辑器设置
  setIsModalOpen, // 控制编辑器设置弹窗
  selectedQuestion, // 当前选中的题目
  isMobile,
}) => {
  const [currentFile, setCurrentFile] = useState("main.py"); // 当前显示的文件
  const [files, setFiles] = useState({}); // 文件内容列表

  // 初始化文件列表
  useEffect(() => {
    if (selectedQuestion) {
      const questionFiles = selectedQuestion.file_names?.reduce((acc, fileName, idx) => {
        acc[fileName] = {
          name: fileName,
          value: selectedQuestion.file_contents[idx] || "",
        };
        return acc;
      }, {});

      setFiles({
        "main.py": {
          name: "main.py",
          value: selectedQuestion.source_code || "",
        },
        ...questionFiles,
      });

      setCurrentFile("main.py"); // 切换到默认文件
    }
  }, [selectedQuestion]);

  // 切换当前文件
  const handleTabChange = (event, newFile) => {
    setCurrentFile(newFile); // 更新当前文件
  };

  // 编辑器内容变化
  const handleEditorChange = (newValue) => {
    setFiles((prev) => ({
      ...prev,
      [currentFile]: {
        ...prev[currentFile],
        value: newValue,
      },
    }));

    if (currentFile === "main.py") {
      setUserCode(newValue); // 更新父组件的 userCode
    }
  };

  // 重置当前文件内容
  const handleReset = () => {
    const resetValue =
      currentFile === "main.py"
        ? selectedQuestion?.source_code || ""
        : selectedQuestion?.file_contents[
        selectedQuestion.file_names?.indexOf(currentFile)
        ] || "";

    setFiles((prev) => ({
      ...prev,
      [currentFile]: {
        ...prev[currentFile],
        value: resetValue,
      },
    }));

    if (currentFile === "main.py") {
      setUserCode(resetValue); // 更新父组件的 userCode
    }
  };

  // 获取 AceEditor 的 mode
  const getMode = () => (currentFile === "main.py" ? "python" : "text");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1, // 让组件的高度由父容器决定
      }}
    >
      {/* 文件选项卡 */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #ddd",
        }}
      >
        <Tabs
          value={currentFile}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            flexGrow: 1,
            "& .MuiTab-root": {
              textTransform: "none",
              fontSize: "18px", // 增大字体大小
              fontWeight: "bold", // 加粗字体
              transition: "all 0.3s ease",
              "&:hover": {
                backgroundColor: "#f5f5f5",
                color: "#1976d2",
              },
              "&.Mui-selected": {
                backgroundColor: "#e3f2fd",
                color: "#0d47a1",
              },
            },
            "& .MuiTabs-flexContainer": {
              marginBottom: "0", // 去除与底部的间距
            },
            "& .MuiTabs-indicator": {
              bottom: "0", // 确保选中指示器紧贴底部
            },
          }}
        >
          {Object.keys(files).map((fileName) => (
            <Tab key={fileName} label={fileName} value={fileName} />
          ))}
        </Tabs>




        {/* 编辑器设置与重置按钮 */}
        <Box
          sx={{
            display: "flex",
            gap: 2,
          }}
        >
          <Button
            variant="outlined"
            size="medium"
            onClick={() => setIsModalOpen(true)}
          >
            编辑器设置
          </Button>
          <Button
            variant="outlined"
            size="medium"
            onClick={handleReset}
            startIcon={<RestartAltIcon />}
          >
            重置
          </Button>
        </Box>
      </Box>

      {/* Ace Editor */}
      <Box>
        {currentFile && (
          <AceEditor
            mode={getMode()} // 动态切换语言模式
            theme={editorSettings.theme === "monokai" ? "monokai" : "github"}
            value={currentFile === "main.py" ? userCode : files[currentFile]?.value || ""} // 父组件传递的 userCode
            onChange={handleEditorChange}
            fontSize={editorSettings.fontSize}
            width="100%"
            setOptions={{
              useWorker: false,
              tabSize: editorSettings.indentSize,
              showPrintMargin: false,
              readOnly: currentFile !== "main.py", // 只有 main.py 可编辑
              showGutter: !isMobile, // 在手机端不显示行号
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default EditorSection;
