import React from 'react';
import { Typography, Box } from '@mui/material';

const QuestionHeader = ({ selectedQuestion }) => {
  const textStyle = {
    fontSize: {
      xs: '1.6rem',
      sm: '1.6rem',
      md: '1.4rem',
    },
    fontWeight: "bold",
    whiteSpace: 'pre-line',
  };

  if (!selectedQuestion) {
    return (
      <Typography
        variant="h4"
        gutterBottom
        sx={textStyle}
      >
        正在加载题目...
      </Typography>
    );
  }

  return (
    <Box>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontSize: {
            xs: '1.6rem',
            sm: '1.6rem',
            md: '1.4rem',
          },
          fontWeight: "bold",
          whiteSpace: 'pre-line',
        }}
      >
        {selectedQuestion.name}
      </Typography>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontSize: {
            xs: '1.2rem',
            sm: '1.4rem',
            md: '1.2rem',
          },
          whiteSpace: 'pre-line',
        }}
      >
        {selectedQuestion.title}
      </Typography>
    </Box>
  );
};

export default QuestionHeader;
