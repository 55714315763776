// src/pages/HomePage.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Button, Box, Avatar, Stack } from '@mui/material';
import TypingEffect from 'react-typing-effect';
import { motion } from 'framer-motion';
import BookIcon from '@mui/icons-material/Book';
import CodeIcon from '@mui/icons-material/Code';
import { useTheme } from '@mui/material/styles';
import CountdownTimer from '../components/CountdownTimer';  // 引入倒计时组件
import { Helmet } from 'react-helmet';  // 导入 react-helmet

const HomePage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  // 处理跳转到选择题页面
  const goToMultipleChoice = () => {
    navigate('/multiple-choice'); // 跳转到选择题练习页面
  };

  const gotoProgramming = () => {
    navigate('/programming'); // 跳转到编程题练习页面
  };

  // 定义Framer Motion的动画变体
  const containerVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 1 }
    },
  };

  const buttonVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.5, delay: 1 }
    },
    hover: {
      scale: 1.05,
      boxShadow: "0px 0px 8px rgb(0, 0, 0)",
      transition: {
        duration: 0.3,
        yoyo: Infinity,
      },
    },
  };

  return (

    <Box>
      <Helmet>
        <title>Python二级真题库 | Python二级在线练习平台 - 免下载即练即评+精解</title>
        <meta name="description"
          content="python二级在线,全国计算机二级Python一站式备考平台，可查看考试时间，支持在线代码评分与Turtle库、jieba库运行，涵盖近5年Python二级真题练习，无需配置环境，提供编程题参考答案与选择题逐题解析，PC/手机随时学习。" />
        <meta name="keywords"
          content="Python二级,python二级在线,计算机二级python,全国计算机二级,计算机二级python,中国教育考试网计算机二级,中国教育考试网python二级,ncre,python二级题库,计算机二级考试时间,pythonerji, python2ji, 代码题,计算机二级python题库, Python二级真题, 在线编程评分, 无需下载安装, Turtle库, jieba库, Python编程题, 二级考试备考, Python操作题, Python选择题, python二级刷题, python二级真题, 二级答案解析, python2ji在线刷题, 二级编程题, 不需要配置环境, 不需安装环境" />
      </Helmet>

      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          textAlign: 'center',
          px: 2, // 为小屏幕添加内边距
          py: { xs: 2, md: 8 }, // 增加上下内边距
          marginTop: '-96px',
        }}
      >

        {/* Logo 和 欢迎标题区域 */}
        <Stack spacing={2} alignItems="center" mb={4}>
          {/* Logo */}
          <Avatar
            src="/icon.png"
            alt="主页图片"
            sx={{
              width: { xs: 160, sm: 200 },
              height: { xs: 160, sm: 200 },
            }}
          />

          {/* 欢迎标题和打字动画 */}
          <motion.div
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            <Typography variant="h1" gutterBottom sx={{ fontSize: { xs: '2.4rem', sm: '2.6rem' }, fontWeight: 500 }}>
              python二级在线
            </Typography>

            <Typography variant="h2" gutterBottom sx={{ fontSize: { xs: '1.6rem', sm: '2rem' } , fontWeight: 500}}>
              <TypingEffect
                text={['print("无需环境,从真题开始！")']}
                speed={100}
                eraseDelay={100000} // 不擦除
                displayTextRenderer={(text, i) => {
                  // 分析文本并添加语法高亮
                  const parts = text.split(/(print|"[^"]+")/g);
                  return (
                    <span>
                      {parts.map((part, index) => {
                        if (part === 'print') {
                          return (
                            <span
                              key={index}
                              style={{
                                color: isDarkMode ? '#4FC3F7' : '#007ACC', // 蓝色在日间，浅蓝在夜间
                                fontWeight: 'bold',
                              }}
                            >
                              {part}
                            </span>
                          );
                        } else if (/^".+"$/.test(part)) {
                          return (
                            <span
                              key={index}
                              style={{
                                color: isDarkMode ? '#FF8A65' : '#D14', // 橙色在夜间，红色在日间
                              }}
                            >
                              {part}
                            </span>
                          );
                        } else {
                          return part;
                        }
                      })}
                    </span>
                  );
                }}
              />
            </Typography>
          </motion.div>
        </Stack>

        {/* 按钮区域，使用Framer Motion */}
        <motion.div
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0 },
            visible: {
              opacity: 1,
              transition: { duration: 1, delay: 1.5 }
            },
          }}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            flexWrap: 'wrap',
            gap: '16px',
            marginTop: '24px', // 增加打字动画与按钮之间的间距
          }}
        >
          <motion.div
            variants={buttonVariants}
            initial="hidden"
            animate="visible"
            whileHover="hover"
            style={{ width: '100%', maxWidth: '250px' }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={goToMultipleChoice}
              fullWidth
              startIcon={<BookIcon />}
              sx={{
                padding: '12px',
                fontSize: { xs: '16px', sm: '18px' },
              }}
            >
              开始选择题练习
            </Button>
          </motion.div>

          <motion.div
            variants={buttonVariants}
            initial="hidden"
            animate="visible"
            whileHover="hover"
            style={{ width: '100%', maxWidth: '250px' }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={gotoProgramming}
              fullWidth
              startIcon={<CodeIcon />}
              sx={{
                padding: '12px',
                fontSize: { xs: '16px', sm: '18px' },
              }}
            >
              开始程序题练习
            </Button>
          </motion.div>
        </motion.div>
        <Box mt={6}> {/* 增加顶部间隙 */}
          <CountdownTimer /> {/* 倒计时组件 */}
        </Box>
      </Container>

      {/* 添加CSS样式 */}
      <style jsx="true">{`
        .syntax-print {
          color: #007ACC; /* 蓝色 */
          font-weight: bold;
        }
        .syntax-string {
          color: #D14; /* 红色 */
        }
        /* 夜间模式颜色调整 */
        body[data-theme='dark'] .syntax-print {
          color: #4FC3F7; /* 浅蓝色 */
        }
        body[data-theme='dark'] .syntax-string {
          color: #FF8A65; /* 橙色 */
        }
      `}</style>
    </Box>
  );
};

export default HomePage;
