import React from 'react';
import Countdown from 'react-countdown';
import { Typography, Box } from '@mui/material';

// 鼓励语数组
const encouragements = [
  "今天是考试的日子，加油哦(๑•̀ㅂ•́)و✧",
];

// 渲染倒计时结束后的内容
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  const currentDate = new Date();
  let examDate;

  // 判断当前时间是否属于上半年还是下半年
  if (currentDate.getMonth() < 8) {
    examDate = new Date('March 23, 2025 00:00:00'); // 上半年考试时间
  } else {
    examDate = new Date('September 21, 2025 00:00:00'); // 下半年考试时间
  }

  const isExamDay = currentDate >= examDate && currentDate <= new Date(examDate.getTime() + 3 * 24 * 60 * 60 * 1000); // 判断是否在考试期间

  const isDarkMode = document.body.dataset.theme === 'dark'; // 检测是否是夜间模式

  const printColor = isDarkMode ? '#4FC3F7' : '#007ACC'; // 夜间模式浅蓝色，白天蓝色
  const stringColor = isDarkMode ? '#FF8A65' : '#D14'; // 夜间模式橙色，白天红色

  const randomEncouragement = encouragements[Math.floor(Math.random() * encouragements.length)];

  if (completed) {
    return <Typography variant="h4" sx={{ color: printColor, fontSize: { xs: '1rem', sm: '1.4rem' }, gutterBottom: true }}>考试已结束</Typography>;
  } else {
    return (
      <Box>
        <Typography variant="h4" sx={{ color: printColor, fontSize: { xs: '1rem', sm: '1.2rem' }, fontWeight: 'bold', display: 'inline', marginRight: 1 }}>
          距离下次考试还有
        </Typography>
        <Typography variant="h3" sx={{ color: printColor, fontSize: { xs: '1.2rem', sm: '1.4rem' }, fontWeight: 'bold', display: 'inline', marginRight: 1 }}>
          {days}天 {hours}小时 {minutes}分钟 {seconds}秒
        </Typography>
        {isExamDay && (
          <Typography variant="h5" sx={{ marginTop: 2, color: stringColor, fontSize: { xs: '1rem', sm: '1.4rem' }, display: 'inline', marginLeft: 2 }}>
            {randomEncouragement}
          </Typography>
        )}
      </Box>
    );
  }
};

const CountdownTimer = () => {
  const currentDate = new Date();
  let examDate;

  // 判断当前时间是否属于上半年还是下半年
  if (currentDate.getMonth() < 8) {
    examDate = new Date('March 23, 2025 00:00:00'); // 上半年考试时间
  } else {
    examDate = new Date('September 21, 2025 00:00:00'); // 下半年考试时间
  }

  return (
    <Countdown
      date={examDate}
      renderer={renderer} // 使用自定义渲染器
    />
  );
};

export default CountdownTimer;
