import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Container,
  Grid,
  Typography,
  Paper,
  Box,
  TextField,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import CodeIcon from "@mui/icons-material/Code";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import UpdateIcon from "@mui/icons-material/Update";
import StarIcon from "@mui/icons-material/Star"; // 新的图标
import AnimationIcon from "@mui/icons-material/Animation";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";
import LoginDialog from "../components/UpgradePage/LoginDialog";

const UpgradePage = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
  const isMounted = useRef(false);
  const confirmButtonRef = useRef(null);
  const loginButtonRef = useRef(null);
  const [redeemEmail, setRedeemEmail] = useState('');
  const [redeemCode, setRedeemCode] = useState('');
  const [isRedeemDialogOpen, setIsRedeemDialogOpen] = useState(false);
  const [redeemLoading, setRedeemLoading] = useState(false);
// 在组件中保持验证函数
const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// 兑换处理函数
const handleRedeem = async () => {
  // 邮箱格式验证
  if (!validateEmail(redeemEmail)) {
    alert("请输入有效的邮箱地址");
    return;
  }

  // 兑换码非空验证
  if (!redeemCode.trim()) {
    alert("请输入兑换码");
    return;
  }

  setRedeemLoading(true);
  
  try {
    const response = await fetch('/api/redeem_code', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        email: redeemEmail,
        coupon_code: redeemCode
      }),
    });

    const data = await response.json();

    if (!response.ok) throw new Error(data.message || '兑换失败');

    // 兑换成功处理
    try {
      const loginRes = await axios.post('/auth/login', { uid: data.uid });
      if (loginRes.data.token) {
        localStorage.setItem("token", loginRes.data.token);
        alert("兑换成功！已自动登录");
        window.location.href = "/user";
      }
    } catch (loginError) {
      console.error("自动登录失败:", loginError);
      alert("兑换成功！请使用邮件中的链接登录");
    }
    
  } catch (error) {
    alert(error.message);
  } finally {
    setRedeemLoading(false);
    setIsRedeemDialogOpen(false);
    // 清空输入（可选）
    setRedeemEmail('');
    setRedeemCode('');
  }
};
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };



  const handleSubmit = () => {
    if (validateEmail(email)) {
      setIsConfirmDialogOpen(true);
    } else {
      alert("请输入有效的邮箱地址");
    }
  };

  const handleConfirm = async () => {
    if (!isMounted.current) return;
    setIsConfirmDialogOpen(false);
    setIsLoading(true);
    try {
      const response = await axios.post("/api/create-alipay-order", {
        email,
      });
      if (
        response.status === 200 &&
        response.data.status === "success"
      ) {
        const { pay_url } = response.data;
        window.location.href = pay_url;
      } else {
        if (isMounted.current) {
          alert("创建订单失败，请重试！");
        }
      }
    } catch (error) {
      if (isMounted.current) {
        console.error("Error creating order:", error);
        alert("创建订单失败，请重试！");
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  };

  const isDarkMode = theme.palette.mode === "dark";

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 4, mb: 4, textAlign: isMobile ? "center" : "left" }}
    >
       <Helmet>
        <title>升级会员 | Python二级在线</title>
        <meta name="description" content="升级为会员，享受更多功能和特权！" />
        <meta property="og:title" content="升级会员 | Python二级在线" />
        <meta property="og:description" content="升级为会员，享受更多功能和特权！" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.python2ji.com/upgrade" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="升级会员 | Python二级在线" />
        <meta name="twitter:description" content="升级为会员，享受更多功能和特权！" />
      </Helmet>

      <Grid
        container
        spacing={4}
        sx={
          isMobile
            ? { justifyContent: "center", alignItems: "center" }
            : {}
        }
      >
        {/* 左侧：会员专属服务展示 */}
        {!isMobile && (
          <Grid item xs={12} md={7}>
            <Paper elevation={3} sx={{ p: 4, minHeight: "70vh" }}>
              <Typography
                variant="h5" // 调整为 h5
                gutterBottom
                sx={{
                  color: isDarkMode
                    ? theme.palette.secondary.main
                    : "inherit",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <StarIcon
                  sx={{
                    fontSize: "1.8rem",
                    mr: 1,
                    color: "#FF5722",
                  }}
                />
                会员专属服务
              </Typography>
              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h6"
                  sx={{
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1rem",
                  }}
                >
                  <LibraryBooksIcon
                    sx={{ mr: 1, color: "#1976d2" }}
                  />
                  解锁完整选择题题库
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1rem",
                  }}
                >
                  <CodeIcon sx={{ mr: 1, color: "#1976d2" }} />
                  解锁编程题库
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1rem",
                  }}
                >
                  <PlayCircleFilledIcon
                    sx={{ mr: 1, color: "#1976d2" }}
                  />
                  代码评分功能
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1rem",
                  }}
                >
                  <UpdateIcon sx={{ mr: 1, color: "#1976d2" }} />
                  无限次的调试功能
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1rem",
                  }}
                >
                  <AnimationIcon sx={{ mr: 1, color: "#1976d2" }} />
                  海龟库支持 在线查看执行效果
                </Typography>
              </Box>
            </Paper>
          </Grid>
        )}

        {/* 右侧：购买区域 */}
        <Grid item xs={12} md={isMobile ? 12 : 5}>
          <Paper
            elevation={3}
            sx={{
              p: 4,
              minHeight: "70vh",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start", // 从顶部开始排列
              alignItems: isMobile ? "center" : "stretch",
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                color: isDarkMode
                  ? theme.palette.secondary.main
                  : "inherit",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mb: 2, // 增加下边距
              }}
            >
              <EmojiEventsIcon
                sx={{
                  fontSize: "1.8rem",
                  mr: 1,
                  color: "#FF5722",
                }}
              />
              升级会员
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row", // 固定为 row
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
              }}
            >
              <LocalOfferIcon
                sx={{
                  fontSize: "2rem",
                  color: "#FF5722",
                  mr: 1, // 固定右边距
                  // mb: isMobile ? 1 : 0, // 移除移动端的下边距
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "#FF5722",
                  mr: 2, // 固定右边距
                }}
              >
                14.99元
              </Typography>
              <Typography
                variant="h8"
                sx={{
                  textDecoration: "line-through",
                  color: "gray",
                }}
              >
                36元/三个月（90天）
              </Typography>
            </Box>
            <Box component="form" sx={{ mt: 4, width: "100%" }}>
              <TextField
                label="常用邮箱地址"
                variant="outlined"
                fullWidth
                value={email}
                onChange={handleEmailChange}
                sx={{ mb: 3 }}
              />
              <Button
                ref={confirmButtonRef}
                variant="contained"
                color={
                  !isDarkMode ? "primary" : "secondary"
                }
                fullWidth
                onClick={handleSubmit}
                sx={{ padding: "12px", fontSize: "16px" }}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress
                    size={24}
                    color="inherit"
                  />
                ) : (
                  "立即购买"
                )}
              </Button>
              {/* 确认弹窗 */}
              <Dialog
                open={isConfirmDialogOpen}
                onClose={() => {
                  setIsConfirmDialogOpen(false);
                  confirmButtonRef.current?.focus();
                }}
                keepMounted
              >
                <DialogTitle>确认邮箱地址</DialogTitle>
                <DialogContent>
                  <Typography>
                    您确定您的邮箱是：{email} 吗？
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setIsConfirmDialogOpen(false);
                      confirmButtonRef.current?.focus();
                    }}
                    color="primary"
                  >
                    返回修改
                  </Button>
                  <Button
                    onClick={handleConfirm}
                    color="primary"
                    variant="contained"
                  >
                    确认
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
            {/* 登录按钮 */}
            <Button
              ref={loginButtonRef}
              variant="text"
              color="primary"
              sx={{ mt: 3, fontSize: "1rem" }}
              onClick={() =>
                setIsLoginDialogOpen(true)
              }
            >
              已购买？立即登录
            </Button>
            <LoginDialog
              open={isLoginDialogOpen}
              onClose={() => {
                setIsLoginDialogOpen(false);
                loginButtonRef.current?.focus();
              }}
            />
               {/* 新增兑换码按钮 */}
    <Button
      variant="text"
      color="primary"
      sx={{ mt: 3, fontSize: "1rem" }}
      onClick={() => setIsRedeemDialogOpen(true)} // 弹出兑换码输入框
    >
      使用兑换码
    </Button>

    {/* 兑换码弹窗 */}
    <Dialog
      open={isRedeemDialogOpen}
      onClose={() => setIsRedeemDialogOpen(false)}
      keepMounted
    >
      <DialogTitle>兑换码</DialogTitle>
      <DialogContent>
        <TextField
          label="绑定邮箱地址"
          variant="outlined"
          fullWidth
          value={redeemEmail}
          onChange={(e) => setRedeemEmail(e.target.value)}
          sx={{ mb: 3 }}
        />
        <TextField
          label="请输入兑换码"
          variant="outlined"
          fullWidth
          value={redeemCode}
          onChange={(e) => setRedeemCode(e.target.value)}
          sx={{ mb: 3 }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setIsRedeemDialogOpen(false)}
          color="primary"
        >
          取消
        </Button>
        <Button
          onClick={handleRedeem}
          color="primary"
          variant="contained"
        >
          兑换
        </Button>
      </DialogActions>
    </Dialog>
            {/* 在移动设备上显示会员专属服务信息 */}
            {isMobile && (
              <Box sx={{ mt: 4 }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{
                    color: isDarkMode
                      ? theme.palette.secondary.main
                      : "inherit",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <StarIcon
                    sx={{
                      fontSize: "1.8rem",
                      mr: 1,
                      color: "#FF5722",
                    }}
                  />
                  会员专属服务
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      mt: 1,
                      display: "flex",
                      alignItems: "center",
                      fontSize: "1rem",
                      justifyContent: "center",
                    }}
                  >
                    <LibraryBooksIcon
                      sx={{ mr: 1, color: "#1976d2" }}
                    />
                    解锁完整选择题题库
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      mt: 1,
                      display: "flex",
                      alignItems: "center",
                      fontSize: "1rem",
                      justifyContent: "center",
                    }}
                  >
                    <CodeIcon
                      sx={{ mr: 1, color: "#1976d2" }}
                    />
                    解锁编程题库
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      mt: 1,
                      display: "flex",
                      alignItems: "center",
                      fontSize: "1rem",
                      justifyContent: "center",
                    }}
                  >
                    <PlayCircleFilledIcon
                      sx={{ mr: 1, color: "#1976d2" }}
                    />
                    在线代码评测功能
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      mt: 1,
                      display: "flex",
                      alignItems: "center",
                      fontSize: "1rem",
                      justifyContent: "center",
                    }}
                  >
                    <UpdateIcon
                      sx={{ mr: 1, color: "#1976d2" }}
                    />
                    定期更新和维护
                  </Typography>
                </Box>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UpgradePage;
